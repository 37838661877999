import { 
	adminSummaryApiURL, 
	filesApiURL, filesUploadApiURL, 
	getGroupScoreSummaryApiURL, getJourneyApiURL, 
	getScoresApiURL, getSummaryApiURL, 
	groupsApiURL, groupsInGroupAllApiURL, groupsInGroupApiURL, groupsInOrgAllApiURL, groupsInOrgApiURL, 
	organizationsApiURL, organizationsApiURLChunk, particularGroupInParticularOrgApiURL, 
	puppeteerApiURL, scoreSummaryTrendApiURL, 
	singleGroupApiURL, singleOrganizationApiURL, 
	staticFilesDownloadApiURL } from "./ApiManager/List";
import { responseVerification } from "./ApiManager/Methods";
import { API_METHODS } from "./ApiManager/ResponseCodes";
import { JOURNEY_DEFAULT_NAME } from "./JourneyManager";
import { searchURL } from "./ApiManager/List";
import { formatDate } from "../utils/DateTimeUtil";
import { setOrganizationDetails, setOrganizationSummary, setOrganizationTestSummary, setGroupDetails, setGroupSummary, setGroupTestSummary } from "../redux/actions/orgAction";
import { setFailureStatus } from "../redux/actions/appActions";
import { setLocalUserAccess } from "./UserManager";
import * as Config from "../utils/config/Config";
import { store } from "..";
import { getUserRole, getDefaultOrgId, getDefaultGroupId, getOrgPlan } from "./UserManager";
import logoGeneric from "../assets/img/brand/logo-think-forward-300.png"
import logoReflect from "../assets/img/brand/logo-think-forward-300.png"
import API_CALL from "./ApiManager";

export const PLAN_REFLECT_EF = "REFLECT_EF";
export const PLAN_DEFAULT = PLAN_REFLECT_EF;

export const Plan = Object.freeze({
	PLAN_REFLECT_EF: "REFLECT_EF"
});

const planArray = [
	{
		name: "REFLECT/EF",
		code: PLAN_REFLECT_EF,
		color: 'dark',
		level: 30,
		logo: logoReflect
	}
];

export const getPlanDisplayColor = (code) => {
	let displayColor = "secondary"
	let planOption = planArray.find((plan, index) => {
		return plan.code === code
	})
	if (planOption) {
		displayColor = planOption.color
	}
	return displayColor
}

export const getPlanLogo = (code) => {
	let logo = logoGeneric
	let planOption = planArray.find((plan, index) => {
		return plan.code === code
	})
	if (planOption) {
		logo = planOption.logo
	}
	return logo
}

export const getPlanDisplayName = (code) => {
	let displayName = ""
	let planOption = planArray.find((plan, index) => {
		return plan.code === code
	})
	if (planOption) {
		displayName = planOption.name
	}
	return displayName
}

export const getPlanFilterOptions = () => {
	let filterOptions = {};
	planArray.map((plan, index) => {
		filterOptions[plan.code] = plan.name
	});
	return filterOptions;
}

export const getPlanSelectOptions = () => {
	let options = []
	planArray.map((plan, index) => {
		options.push(
			{
				value: plan.code,
				label: getPlanDisplayName(plan.code),
			}
		)
	});
	return options;
}

export const getSelectedPlan = (matchPlan) => {
	return getPlanSelectOptions().find((plan) => {
		return (plan.value === matchPlan) ? plan : null;
	});
};



export function getOrgURL(orgId, userRoleCode, plan) {
	if (!orgId) {
		orgId = 0;
	}
	let url = "/organizations/" + orgId;
	// if they only have access to groups, append it to the org URL
	if (userRoleCode === Config.ROLE_CODE_PARTICIPANT_L1 || userRoleCode === Config.ROLE_CODE_PARTICIPANT_L2 ||
			userRoleCode === Config.ROLE_CODE_PARTICIPANT_L3 || userRoleCode === Config.ROLE_CODE_GROUP_ADMIN) {
		url += "/defaultGroup";
	}
	return url;
}

export const getOrganizationDetails = (orgId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = singleOrganizationApiURL(orgId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const modifyOrganization = (orgId, accessToken, methods, successCallback, errorCallback, payload = null, isVerificationRequired = true) => {
	let url = singleOrganizationApiURL(orgId)
	let method = (methods == "PUT") ? API_METHODS.PUT : API_METHODS.DELETE
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const createOrganization = (accessToken, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = organizationsApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getOrganizationChunkData = (startIndex, endIndex, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = organizationsApiURLChunk(startIndex, endIndex)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupDetails = (orgId, groupId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = singleGroupApiURL(orgId, groupId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getOrganizationSummary = (orgId, accessToken, successCallback, errorCallback, groupId = 0, startDate = null, endDate = null, isVerificationRequired = true) => {
	let url = getSummaryApiURL(orgId, groupId, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getOrganizationTestSummaries = (orgId, accessToken, successCallback, errorCallback, groupId = null, startDate = null, endDate = null, isVerificationRequired = true) => {
	let url = scoreSummaryTrendApiURL(orgId, groupId, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getReportsFor = (accessToken, payloadData, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = puppeteerApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payloadData, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getFilesForExport = (accessToken, payloadData, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = filesApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payloadData, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const putFilesForImportExport = (accessToken, payloadData, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = filesApiURL
	let method = API_METHODS.PUT
	API_CALL(accessToken, method, url, payloadData, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode, false)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const downloadStaticFilesFor = (fileId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = staticFilesDownloadApiURL(fileId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getPresignedURLforExtension = (fileExt, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = filesUploadApiURL(fileExt)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupsInGroups = (orgId, groupId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = groupsInGroupApiURL(orgId, groupId);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupsOfOrgs = (orgId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = groupsInOrgApiURL(orgId);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const addNewGroup = (accessToken, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = groupsApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getSingleGroupInOrg = (orgId, groupId, methods, accessToken, successCallback, errorCallback, payload = null, isVerificationRequired = true) => {
	let url = particularGroupInParticularOrgApiURL(orgId, groupId)
	let method = (methods == "PUT") ? API_METHODS.PUT : API_METHODS.DELETE
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getAllGroupsData = (orgId, groupId = null, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	var getGroupsURL = null
	if (groupId != 0) {
		getGroupsURL = groupsInGroupAllApiURL(orgId, groupId);
	} else {
		getGroupsURL = groupsInOrgAllApiURL(orgId);
	}
	let method = API_METHODS.GET
	API_CALL(accessToken, method, getGroupsURL, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode, false)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getScoresData = (orgId, groupId, startDate = null, endDate = null, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	var url = getScoresApiURL(orgId, groupId, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupScoreSummary = (orgId, groupId, accessToken, successCallback, errorCallback, startDate = null, endDate = null) => {
	var url = (startDate == null && endDate == null) ? getGroupScoreSummaryApiURL(orgId, groupId) : getGroupScoreSummaryApiURL(orgId, groupId, startDate, endDate);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}


/**
 * First use journey from Group, then Org, and default if neither is set
 * @param {*} org 
 * @param {*} group 
 * @returns 
 */
export const getDefaultJourneyTypeName = (org, group) => {
	if (group?.journeyTypeName?.length > 0) {
		return group?.journeyTypeName
	} else if (org?.journeyTypeName?.length > 0) {
		return org?.journeyTypeName
	}
	return JOURNEY_DEFAULT_NAME
}

export const addNewJourney = (accessToken, orgId, groupId, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId);
	let method = API_METHODS.POST;
	API_CALL(accessToken, method, url, payload, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const editJourney = (accessToken, orgId, groupId, journeyId, data, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId, journeyId)
	let method = API_METHODS.PUT
	API_CALL(accessToken, method, url, data, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getJourneyDetail = (accessToken, orgId, groupId, journeyId, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId, journeyId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getAdminSummaryData = (accessToken, orgPlan, startDate, endDate, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = adminSummaryApiURL(orgPlan, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getOrganizationFilterChunkData = (startIndex, endIndex, sortField, sortOrder, filters, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = organizationsApiURLChunk(startIndex, endIndex, sortField, sortOrder, filters)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const searchGlobalData = (accessToken, searchStr, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = searchURL(searchStr);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getOrg = async (orgId, successCallback, errorCallback) => {
	const userAccess = store.getState()?.user?.userAccess;
	const userProfile = store.getState()?.user?.userProfile;
	await getOrganizationDetails(orgId, userAccess?.accessToken, async (result) => {
		await store.dispatch(setOrganizationDetails(result))
		let currentOrgId = orgId || getDefaultOrgId(userProfile);
		// Commenting out for now as this should not be necessary since this is handled upon switching orgs
		await setLocalUserAccess(currentOrgId, getDefaultGroupId(currentOrgId, userProfile.groups), getUserRole(currentOrgId, userProfile), getOrgPlan(currentOrgId, userProfile))
		if (successCallback) {
			successCallback(result)
		}
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	});
};

export const getGroup = async (orgId, groupId, successCallback, errorCallback) => {
	const userAccess = store.getState()?.user?.userAccess;
	const userProfile = store.getState()?.user?.userProfile;
	await getGroupDetails(orgId, groupId, userAccess?.accessToken, async (result) => {
		await store.dispatch(setGroupDetails(result));
		let currentOrgId = orgId || getDefaultOrgId(userProfile);
		await setLocalUserAccess(currentOrgId, groupId || getDefaultGroupId(currentOrgId, userProfile.groups), getUserRole(currentOrgId, userProfile), getOrgPlan(currentOrgId, userProfile))
		if (successCallback) {
			successCallback(result)
		}
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	});
};

export const getOrgSummary = (orgId, startDate, endDate, successCallback, errorCallback) => {
	const accessToken = store.getState()?.user?.userAccess?.accessToken;
	getOrganizationSummary(orgId, accessToken, async (result) => {
		await store.dispatch(setOrganizationSummary(result));
		if (successCallback) {
			successCallback(result)
		}
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	}, 0, formatDate(startDate, 'YYYY-MM-DD', ""), formatDate(endDate, 'YYYY-MM-DD', ""));
}

export const getGroupSummary = (orgId, groupId, startDate, endDate, successCallback, errorCallback) => {
	const accessToken = store.getState()?.user?.userAccess?.accessToken;
	getOrganizationSummary(orgId, accessToken, async (result) => {
		await store.dispatch(setGroupSummary(result));
		if (successCallback) {
			successCallback(result)
		}
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	}, groupId, formatDate(startDate, 'YYYY-MM-DD', ""), formatDate(endDate, 'YYYY-MM-DD', ""));
}

export const getOrgTestSummaries = (orgId, startDate, endDate, successCallback, errorCallback) => {
	const accessToken = store.getState()?.user?.userAccess?.accessToken;
	getOrganizationTestSummaries(orgId, accessToken, async (result) => {
		await store.dispatch(setOrganizationTestSummary(result));
		if (successCallback) {
			successCallback(result)
		}
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	}, 0, formatDate(startDate, 'YYYY-MM-DD', ""), formatDate(endDate, 'YYYY-MM-DD', ""));
}

export const getGroupTestSummaries = (orgId, groupId, startDate, endDate, successCallback, errorCallback) => {
	const accessToken = store.getState()?.user?.userAccess?.accessToken;
	getOrganizationTestSummaries(orgId, accessToken, async (result) => {
		await store.dispatch(setGroupTestSummary(result));
		if (successCallback) {
			successCallback(result)
		}
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	}, groupId, formatDate(startDate, 'YYYY-MM-DD', ""), formatDate(endDate, 'YYYY-MM-DD', ""));
}

export const refreshData = (orgId, successCallback, errorCallback) => {
	let url = getRefreshDataApiURL(orgId);
	const accessToken = store.getState()?.user?.userAccess?.accessToken;
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, "", (successData) => {
		successCallback(successData);
	}, (error) => {
		store.dispatch(setFailureStatus(true));
		if (errorCallback) {
			errorCallback(error)
		}
	});

}