/*
This file contains the list of all the RESTful-APIs that
the project will be using .
To be used with "fetch" method.
*/

const baseURLs = [
	"https://dev-api.reflectiveperformanceinc.com/dev",
	"https://dev-api.reflectiveperformanceinc.com/dev",
	"https://staging-api.reflectiveperformanceinc.com/staging",
	"https://api.reflectiveperformanceinc.com/prod"
];

const baseURL = baseURLs[process.env.REACT_APP_HOST_ENV];

//params
const OFFSET_PARAM = "offset";
const STATUS_PARAM = "status";
const SIZE_PARAM = "size";
const SEARCH_PARAM = "searchStr";
const SORT_FIELD = "sortField";
const SORT_ORDER = "sortOrder";
const START_DATE = "startDate";
const END_DATE = "endDate";
const FILTERS = "filters";
const FILE_ID = "fileId";
const FILE_EXT = "fileExt";
const FOR_USER_PARAM = "forUser";
const IS_SUMMARY_PARAM = "isSummary";
const SUMMARY_TYPE_SCORE_TREND_PARAM = "summaryType";
const PARTICIPANT_ID_PARAM = "participantId";
const ORG_ID_PARAM = "organizationId";
const GROUP_ID_PARAM = "groupId";
const ORG_PLAN_PARAM = "orgPlan";
const INCLUDE_HIERARCHY_PARAM = "groupsHierarchy";
const ADD_PARTICIPANT_TYPE = "addParticipantType";
const ARCHIVE_PARTICIPANTS = "archiveParticipants";
const SESSION_TOKEN_ID = "tokenId";
const ACTION = "action";

const getParameter = (separator, parameterName, value) => {
	return (
		separator + parameterName + "=" + encodeURIComponent(value)
	)
};

//literals
const __FORWARD_SLASH__ = "/";
const __SUMMARY__ = "/summary";
const __ORGANIZATIONS__ = "/organizations";
const __PARTNERS__ = "/partner";
const __USERS__ = "/users";
const __PARTICIPANTS__ = "/participants";
const __CLEAN__ = "/clean";
const __GROUPS__ = "/groups";
const __ASSESSMENTS__ = "/assessments";
const __SESSIONS__ = "/sessions";
const __BY_ASSESSMENT__ = "/by-assessment";
const __FILES__ = "/files";
const __SCORES__ = "/scores";
const __SEARCH__ = "/search";
const __USERINFO__ = "/userinfo";
const __PUPPETEER__ = "/puppeteer";
const __IMPORT__ = "/import";
const __JOURNEY__ = "/journey";

//BASE api URLS
export const organizationsApiURL = baseURL + __ORGANIZATIONS__;
export const partnersApiURL = baseURL + __PARTNERS__;
export const usersApiURL = baseURL + __USERS__;
export const participantsApiURL = baseURL + __PARTICIPANTS__;
export const groupsApiURL = baseURL + __GROUPS__;
export const sessionsApiURL = baseURL + __SESSIONS__;
export const filesApiURL = baseURL + __FILES__;
export const searchApiURL = baseURL + __SEARCH__;
export const assessmentsApiURL = baseURL + __ASSESSMENTS__;
export const userInfoApiURL = baseURL + __USERINFO__;
export const puppeteerApiURL = baseURL + __PUPPETEER__;
export const journeyApiURL = baseURL + __JOURNEY__;

//summary api URLS
export const participantsSummaryApiURL = participantsApiURL + __SUMMARY__;

// for internal admin dashboard
export const adminSummaryApiURL = (orgPlan, startDate, endDate) => {
	return (
		organizationsApiURL + __SUMMARY__
		+ getParameter("?", ORG_PLAN_PARAM, orgPlan) + getParameter("&", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	)
}

// scores summary
export const getScoresApiURL = (orgId, groupId, startDate, endDate) => {
	if (groupId > 0) {
		return organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId + __SCORES__
			+ getParameter("?", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	} else {
		return organizationsApiURL + __FORWARD_SLASH__ + orgId + __SCORES__
			+ getParameter("?", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	}
}

export const getGroupScoreSummaryApiURL = (orgId, groupId, startDate, endDate) => {
	return organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId + __SCORES__ + __SUMMARY__
		+ getParameter("?", IS_SUMMARY_PARAM, "true") + getParameter("&", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
}
export const scoreSummaryTrendApiURL = (orgId, groupId, startDate, endDate) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId + __SCORES__ + __SUMMARY__
		+ getParameter("?", SUMMARY_TYPE_SCORE_TREND_PARAM, "scoreTrend")
		+ getParameter("&", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	)
}

//########################################_ORGANIZATIONS_########################################
export const organizationsApiURLChunk = (offset, size, sortField, sortOrder, filters) => {
	return (
		organizationsApiURL + getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size) + getParameter("&", SORT_FIELD, sortField) + getParameter("&", SORT_ORDER, sortOrder) + getParameter("&", FILTERS, filters)
	)
}

export const singleOrganizationApiURL = (orgId) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId
	)
}

export const getSummaryApiURL = (orgId, groupId, startDate, endDate) => {
	if (groupId > 0) {
		return organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId + __SUMMARY__
			+ getParameter("?", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	} else {
		return organizationsApiURL + __FORWARD_SLASH__ + orgId + __SUMMARY__
			+ getParameter("?", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	}
}

export const partnerSyncApiURL = (partnerId) => {
	return (
		partnersApiURL + "/" + partnerId + "/sync/"
	)
}

//########################################_USERS_########################################

export const usersApiURLChunk = (offset, size) => {
	return (
		usersApiURL + getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
	)
}

export const singleUserApiURL = (id) => {
	return (
		usersApiURL + __FORWARD_SLASH__ + id
	)
}

export const updateEmailSingleUserApiURL = (id) => {
	return (
		usersApiURL + __FORWARD_SLASH__ + id + getParameter("?", ACTION, "updateEmail")
	)
}

export const singleUserApiURLInOrg = (id, orgId) => {
	return (
		usersApiURL + __FORWARD_SLASH__ + id + getParameter("?", ORG_ID_PARAM, orgId)
	)
}

//########################################_PARTICIPANTS_########################################
export const getParticipantApiURL = (orgId, groupId, participantId) => {
	let url = organizationsApiURL + __FORWARD_SLASH__ + orgId
	if (groupId > 0) {
		url = url + __GROUPS__ + __FORWARD_SLASH__ + groupId
	}
	url = url + __PARTICIPANTS__ + __FORWARD_SLASH__ + participantId
	return url
}

export const getReassignParticipantsApiURL = (orgId, groupId, participantId, orgLevel) => {
	let url = organizationsApiURL + __FORWARD_SLASH__ + orgId
	if (groupId > 0 && !orgLevel) {
		url = url + __GROUPS__ + __FORWARD_SLASH__ + groupId
	}
	url = url + __PARTICIPANTS__ + __FORWARD_SLASH__ + participantId + getParameter("?", ACTION, "getReassignParticipants")
	return url
}

export const getParticipantsInActivationApiURL = (orgId, groupId, orgLevel) => {
	let url = organizationsApiURL + __FORWARD_SLASH__ + orgId
	if (groupId > 0 && !orgLevel) {
		url = url + __GROUPS__ + __FORWARD_SLASH__ + groupId
	}
	url = url + __PARTICIPANTS__ + getParameter("?", ACTION, "bulkInactivate")
	return url
}

export const getParticipantsActivationApiURL = (orgId, groupId, orgLevel) => {
	let url = organizationsApiURL + __FORWARD_SLASH__ + orgId
	if (groupId > 0 && !orgLevel) {
		url = url + __GROUPS__ + __FORWARD_SLASH__ + groupId
	}
	url = url + __PARTICIPANTS__ + getParameter("?", ACTION, "bulkActivate")
	return url
}

export const getParticipantsDeletionApiURL = (orgId, groupId, orgLevel) => {
	let url = organizationsApiURL + __FORWARD_SLASH__ + orgId
	if (groupId > 0 && !orgLevel) {
		url = url + __GROUPS__ + __FORWARD_SLASH__ + groupId
	}
	url = url + __PARTICIPANTS__ + getParameter("?", ACTION, "bulkDelete")
	return url
}

export const getParticipantsQRCodeApiURL = (orgId, groupId, orgLevel) => {
	let url = organizationsApiURL + __FORWARD_SLASH__ + orgId
	if (groupId > 0 && !orgLevel) {
		url = url + __GROUPS__ + __FORWARD_SLASH__ + groupId
	}
	url = url + __PARTICIPANTS__ + getParameter("?", ACTION, "bulkGenerateQRCode")
	return url
}

export const getBulkAssignToGroupURL = (orgId) => {
	return groupsApiURL + getParameter("?", ORG_ID_PARAM, orgId) + getParameter("&", ACTION, "add")
}

export const getBulkRemoveFromGroupURL = (orgId) => {
	return groupsApiURL + getParameter("?", ORG_ID_PARAM, orgId) + getParameter("&", ACTION, "remove")
}

export const getBulkDeIdentifyURL = (orgId) => {
	return groupsApiURL + getParameter("?", ORG_ID_PARAM, orgId) + getParameter("&", ACTION, "DE_IDENTIFY")
}

export const multipleParticipantApiURL = (type) => {
	return (
		participantsApiURL + getParameter("?", ADD_PARTICIPANT_TYPE, type)
	)
}

export const participantsImportApiURL = (fileExt) => {
	return (
		participantsApiURL + __IMPORT__ + getParameter("?", FILE_EXT, fileExt)
	)
}

//########################################_GROUPS_########################################
export const groupsApiURLChunk = (orgId, offset, size) => {
	return (
		groupsApiURL + getParameter("?", ORG_ID_PARAM, orgId) + getParameter("&", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
	)
}

export const singleGroupApiURL = (orgId, groupId) => {
	return (
		groupsApiURL + __FORWARD_SLASH__ + groupId + getParameter("?", ORG_ID_PARAM, orgId)
	)
}

export const sessionByTokenApiURL = (tokenId) => {
	return (
		sessionsApiURL + __FORWARD_SLASH__ + tokenId
	)
}


//########################################_FILES_########################################

export const filesReportApiURL = filesApiURL + "/stepfunction-reports";

export const filesUploadApiURL = (fileExt) => {
	return (
		filesApiURL + getParameter("?", FILE_EXT, fileExt)
	)
}

export const staticFilesDownloadApiURL = (fileId) => {
	return (
		filesApiURL + "/static-export" + getParameter("?", FILE_ID, fileId)
	)
}

//########################################_ASSESSMENTS_########################################

export const singleAssessmentApiURL = (id) => {
	return (
		assessmentsApiURL + __FORWARD_SLASH__ + id
	)
}

export const toggleValidityAssessmentApiURL = (id, orgId, groupId, participantId) => {
	return (
		assessmentsApiURL + __FORWARD_SLASH__ + id + getParameter("?", ORG_ID_PARAM, orgId) + getParameter("&", GROUP_ID_PARAM, groupId) + getParameter("&", PARTICIPANT_ID_PARAM, participantId)
	)
}

export const getDeleteAssessmentApiURL = (orgId, participantId, assessmentId) => {
	return (
		singleAssessmentApiURL(assessmentId) + getParameter("?", ORG_ID_PARAM, orgId) + getParameter("&", PARTICIPANT_ID_PARAM, participantId)
	)
}

//########################################_MISCELLANEOUS_########################################

//participants chunk in an org. with order by
export const participantsInOrgApiURLChunk = (orgId, offset, size, sortField, sortOrder, filters, startDate, endDate) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __PARTICIPANTS__
		+ getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
		+ getParameter("&", SORT_FIELD, sortField) + getParameter("&", SORT_ORDER, sortOrder)
		+ getParameter("&", FILTERS, filters)
		+ getParameter("&", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	)
}

//participants chunk in a group with order by
export const participantsInGroupApiURLChunk = (orgId, groupId, offset, size, sortField, sortOrder, filters, startDate, endDate) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId + __PARTICIPANTS__
		+ getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
		+ getParameter("&", ORG_ID_PARAM, orgId)
		+ getParameter("&", SORT_FIELD, sortField) + getParameter("&", SORT_ORDER, sortOrder)
		+ getParameter("&", FILTERS, filters)
		+ getParameter("&", START_DATE, startDate) + getParameter("&", END_DATE, endDate)
	)
}

//users chunk in an org. with order by
export const usersInOrgApiURLChunk = (orgId, offset, size, sortField, sortOrder, filters) => {
	return (
		singleOrganizationApiURL(orgId) + __USERS__
		+ getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
		+ getParameter("&", SORT_FIELD, sortField) + getParameter("&", SORT_ORDER, sortOrder)
		+ getParameter("&", FILTERS, filters)
	)
}

//users chunk in a group with sort by
export const usersInGroupApiURLChunk = (orgId, groupId, offset, size, sortField, sortOrder, filters) => {
	return (
		groupsApiURL + __FORWARD_SLASH__ + groupId + __USERS__
		+ getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
		+ getParameter("&", ORG_ID_PARAM, orgId)
		+ getParameter("&", SORT_FIELD, sortField) + getParameter("&", SORT_ORDER, sortOrder)
		+ getParameter("&", FILTERS, filters)
	)
}

// groups in an org.
export const groupsInOrgApiURL = (orgId) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__
	)
}

// all groups in an org.
export const groupsInOrgAllApiURL = (orgId) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + getParameter("?", INCLUDE_HIERARCHY_PARAM, "true")
	)
}

// groups in a group.
export const groupsInGroupApiURL = (orgId, groupId) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId
	)
}

// groups in a group including participants
export const groupsInGroupAllApiURL = (orgId, groupId) => {
	return (
		organizationsApiURL + __FORWARD_SLASH__ + orgId + __GROUPS__ + __FORWARD_SLASH__ + groupId + getParameter("?", INCLUDE_HIERARCHY_PARAM, "true")
	)
}

export const singleGroupInOrgApiURL = (orgId, groupId) => {
	return (
		groupsInOrgApiURL(orgId) + __FORWARD_SLASH__ + groupId
	)
}

export const particularGroupInParticularOrgApiURL = (orgId, groupId) => {
	return (
		groupsInOrgApiURL(orgId) + __FORWARD_SLASH__ + groupId
	)
}

export const participantsInSameGroupApiURL = (participantId, orgId) => {
	return (
		participantsApiURL + __BY_ASSESSMENT__ + getParameter("?", PARTICIPANT_ID_PARAM, participantId) + getParameter("&", ORG_ID_PARAM, orgId)
	)
}

export const participantsInOrgToCleanURL = (orgId) => {
	return (
		singleOrganizationApiURL(orgId) + __PARTICIPANTS__ + __CLEAN__
	)
}

export const searchURL = (searchStr) => {
	return (
		searchApiURL + getParameter("?", SEARCH_PARAM, searchStr)
	)
}

export const getOrgApiURL = (orgId) => {
	return organizationsApiURL + __FORWARD_SLASH__ + orgId
}

export const getOrgAndGroupApiURL = (orgId, groupId) => {
	return getOrgApiURL(orgId) + __GROUPS__ + __FORWARD_SLASH__ + groupId
}


// journeys in an org.
export const getOrgJourneysApiURL = (orgId, groupId, offset, size, sortField, sortOrder, filters) => {
	return getJourneyAPIChunkURL(orgId, groupId)
		+ getParameter("?", OFFSET_PARAM, offset) + getParameter("&", SIZE_PARAM, size)
		+ getParameter("&", ORG_ID_PARAM, orgId)
		+ getParameter("&", SORT_FIELD, sortField) + getParameter("&", SORT_ORDER, sortOrder)
		+ getParameter("&", FILTERS, filters)
}

export const getJourneyAPIChunkURL = (orgId, groupId) => {
	if (groupId > 0) {
		return getOrgAndGroupApiURL(orgId, groupId) + __JOURNEY__
	} else {
		return getOrgApiURL(orgId) + __JOURNEY__
	}
}

export const getJourneyApiURL = (orgId, groupId, journeyId = 0) => {
	if (journeyId > 0) {
		return getJourneyAPIChunkURL(orgId, groupId) + __FORWARD_SLASH__ + journeyId
	} else {
		return getJourneyAPIChunkURL(orgId, groupId)
	}
}

export const getDeleteJourneyApiURL = (orgId, groupId, journeyId) => {
	return getJourneyAPIChunkURL(orgId, groupId) + __FORWARD_SLASH__ + journeyId
}