import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session';
import helpReducer from "./helpReducer";
import appReducer from "./appReducer";
import orgReducer from "./orgReducer";
import userReducer from "./userReducer";
import coreuiReducer from "./coreuiReducer";

const persistRootConfig = {
  key: "root",
  storage,
  whitelist: ['orgReducer']
};

const persistAppConfig = {
  key: "app",
  storage
};
const persistUserConfig = {
  key: "user",
  storage: sessionStorage,
};

const persistedHelpReducer = persistReducer(persistRootConfig, helpReducer);
const persistedAppReducer = persistReducer(persistAppConfig, appReducer);
const persistedOrgReducer = persistReducer(persistRootConfig, orgReducer);
const persistedCoreUIReducer = persistReducer(persistRootConfig, coreuiReducer);
const persistedUserReducer = persistReducer(persistUserConfig, userReducer);

const rootReducer = combineReducers({
  help: persistedHelpReducer,
  application: persistedAppReducer,
  organization: persistedOrgReducer,
  user: persistedUserReducer,
  coreui: persistedCoreUIReducer,
});

export default rootReducer;
