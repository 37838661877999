import React from 'react'
import { Routes, Route, Outlet } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga4";
import Loading from './views/misc/Loading';
import './scss/style.scss'
import './translations/i18next';
import Logout from './views/misc/Logout.js';
import PartnerAuth from './views/partner/PartnerAuth.js';
import PartnerLaunch from './views/partner/PartnerLaunch.js';
import SessionTimeout from './views/account/Session.js';

// Containers
const AuthCallbackPage = React.lazy(() => import('./auth/AuthCallbackPage.js'))
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'))
const FullPageLayout = React.lazy(() => import('./containers/FullPageLayout'))
const PrintPageLayout = React.lazy(() => import('./containers/PrintPageLayout'))
const PublicLayout = React.lazy(() => import('./containers/PublicLayout'))

function App(props) {
	// Google Analytics
	ReactGA.initialize("G-V6NKN5RB19");

	const { isLoading } = useAuth0();

	if (isLoading) {
		return (
			<div className="page-layout">
				<Loading message="Loading..." />
			</div>
		);
	}

	return (
		<Routes>
			<Route element={<PublicLayout />}>
				<Route path="/account/sessiontimeout" element={<SessionTimeout />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/partner/*/auth" element={<PartnerAuth />} />
			</Route>
			<Route path="/callback" element={<AuthCallbackPage/>} />
			<Route element={<PrintPageLayout />}>
				<Route path="/report/*" />
			</Route>
			<Route element={<FullPageLayout />}>
				<Route path="/signup/*" />
				<Route path="/partner/*/launch" element={<PartnerLaunch />} />
			</Route>
			<Route element={<AdminLayout />}>
				<Route path="/*" element={<Outlet />} />
			</Route>
		</Routes>
	)
}

export default App
