import React from 'react';
import { BrowserRouter } from "react-router-dom"
import App from './App';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducer'; // your root reducer
import { Auth0ProviderWithRedirect } from './auth/Auth0ProviderWithRedirect';

import { PersistGate } from "redux-persist/integration/react"; 
import { persistStore } from "redux-persist"; 
import Loading from "./views/misc/Loading";

export const store = configureStore({
  reducer: rootReducer,
	devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading/>} persistor={persistor}>
        <BrowserRouter>
          <Auth0ProviderWithRedirect>
            <App />
          </Auth0ProviderWithRedirect>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);


