import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { RESET_STATE } from '../../redux/actionTypes/appActionTypes';
import { AUTH_CONFIG } from '../../auth/AuthConfig';
import Loading from './Loading';

export default function Logout(props) {

	const { logout } = useAuth0()
	const dispatch = useDispatch()
	const [isLoaded, setLoaded] = useState(false)
	const clearLocal = async () => {
		await sessionStorage.clear()
		await localStorage.clear()
		await dispatch({ type: RESET_STATE })
		setTimeout(() => {
			setLoaded(true)
			logout({ logoutParams: { returnTo: AUTH_CONFIG.baseUrl } })
		}, 3000)		
	}

	// used to handle react strict mode (where useEffect is called twice)
	const isPagedLoaded = useRef(false);

	useEffect(() => {
		console.log("Logging out. Clearing all information from browser...")
		// used to handle react strict mode (where useEffect is called twice)
		if (!isPagedLoaded.current) {
			isPagedLoaded.current = true
			clearLocal()
		}
	}, [])

	return (
		<div className="sessionMain">
			<Card className="sessionDialog">
				<CardBody>
					<>
						<Loading />
						<h4>Clearing browser settings...</h4>
						<p>Clearing Admin Portal information from browser and logging out</p>
					</>
				</CardBody>
			</Card>
		</div>
	)
}