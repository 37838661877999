/*
This file contains the necessary parameters required for authentication by Auth0,
*/
import * as Config from '../utils/config/Config';

const AUTH0_DOMAIN = [
  "reflectiveperformance-dev.us.auth0.com",
  "reflectiveperformance-dev.us.auth0.com",
  "reflectiveperformance-staging.us.auth0.com",
  "reflectiveperformance.us.auth0.com"
];

const AUTH0_CLIENT_ID = [
  "KAUu0xyfft3zTYoHllv9OtWNlJepi8JJ",
  "KAUu0xyfft3zTYoHllv9OtWNlJepi8JJ",
  "4MqDvj5CoXBN1IZCTJI3HK0sMN9fpBzE",
  "21y51oWC0dxVxGd2YJz36LvFiZdQr5j5"
];

const AUTH0_AUDIENCE = [
  "https://dev-api.reflectiveperformanceinc.com/dev",
  "https://dev-api.reflectiveperformanceinc.com/dev",
  "https://staging-api.reflectiveperformanceinc.com/staging",
  "https://api.reflectiveperformanceinc.com/prod"
];

const AUTH0_CONNECTION = [
  "Username-Password-Authentication",
  "Username-Password-Authentication",  
  "Username-Password-Authentication",
  "Username-Password-Authentication"
];

export const AUTH_CONFIG = {
  domain: AUTH0_DOMAIN[Config.envIndex],
  clientId: AUTH0_CLIENT_ID[Config.envIndex],
  callbackUrl : Config.ENV.baseUrl + "/callback",
	baseUrl : Config.ENV.baseUrl,
  logoutUrl : Config.ENV.baseUrl + "/account/sessiontimeout",
  scope: 'openid email profile',
  responseType: 'token id_token',
  audience: AUTH0_AUDIENCE[Config.envIndex],
  connection: AUTH0_CONNECTION[Config.envIndex],
  resetPasswordApiURL: "https://" + AUTH0_DOMAIN[Config.envIndex] + "/dbconnections/change_password"
}