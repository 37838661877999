/*############################################__ETHNICITIES__############################################*/

const ethnicityArray = [
	{ id: 0, commonName: "native/aboriginal", requestName: "Native / Aboriginal", dbName: "ethnicityNative" },
	{ id: 1, commonName: "asian", requestName: "Asian", dbName: "ethnicityAsian" },
	{ id: 2, commonName: "hawaiian/pacific islander", requestName: "Hawaiian / Pacific Islander", dbName: "ethnicityPacific" },
	{ id: 3, commonName: "black/african", requestName: "Black / African", dbName: "ethnicityBlack" },
	{ id: 4, commonName: "white/caucasian", requestName: "White / Caucasian", dbName: "ethnicityWhite" },
	{ id: 5, commonName: "hispanic or latino", requestName: "Hispanic / Latino", dbName: "ethnicityHispanic" },
	{ id: 6, commonName: "two or more races", requestName: "Two or More Races", dbName: "ethnicityTwoOrMore" }
	//("other/not-specified");
];

const getEthnicityById = (id) => {
	return ethnicityArray.find((ethnicity, index) => { return ethnicity.id == id; })
}


const cleanEthnicityArray = (commaSeperatedEthnicityArray) => {
	//this method will convert  ethnicity-string ( "1,3,5," ) to array ( [1, 3 ,5 ] )...
	const participantsEthnicityArray = [];
	//participant ethnicity is dirty , eg : "1,2,5," we need to clean it and convert into an array ( [1 ,2 ,5]);
	//convert string to array , then, only add wanted parts ""/null/space
	if (commaSeperatedEthnicityArray) {
		(commaSeperatedEthnicityArray.split(',')).map((ethnicity, index) => {
			if ((ethnicity !== "") && (ethnicity) && (ethnicity !== " "))
				participantsEthnicityArray.push(ethnicity);
		});
	}
	return participantsEthnicityArray;
}

const getDisplayableEthnicities = (ethnicityArray) => {
	let newParticipantEthnicityArray = [];
	if (ethnicityArray) {
		newParticipantEthnicityArray = ethnicityArray.map((ethnicityId) => {
			return getEthnicityById(ethnicityId).requestName;
		});
	}
	return newParticipantEthnicityArray;
}

const convertEthnicityObjToArray = (ethnicityObj) => {
	let ethnicityArray = [];

	(ethnicityObj.ethnicityNative) && ethnicityArray.push("0");
	(ethnicityObj.ethnicityAsian) && ethnicityArray.push("1");
	(ethnicityObj.ethnicityPacific) && ethnicityArray.push("2");
	(ethnicityObj.ethnicityBlack) && ethnicityArray.push("3");
	(ethnicityObj.ethnicityWhite) && ethnicityArray.push("4");
	(ethnicityObj.ethnicityHispanic) && ethnicityArray.push("5");
	(ethnicityObj.ethnicityTwoOrMore) && ethnicityArray.push("6");

	return ethnicityArray;
}

const convertEthnicityArrayToString = (ethnicityArray) => {
	//this method will convert array [1 ,4 ,5] to string "1,4,5,"
	let ethnicitydataString = "";

	if (ethnicityArray) {
		ethnicityArray.map((data) => {
			ethnicitydataString += (data + ",")
		});
	}
	return ethnicitydataString;
}

const convertEthnicitiesArrayToObj = (ethnicitiesArray) => {
	let ethnicitiesObj = {
		ethnicityNative: false,
		ethnicityAsian: false,
		ethnicityPacific: false,
		ethnicityBlack: false,
		ethnicityWhite: false,
		ethnicityHispanic: false,
		ethnicityTwoOrMore: false
	}

	if (ethnicityArray) {
		ethnicitiesArray.map((ethnicityId) => {

			if (ethnicityId == 0) {
				ethnicitiesObj.ethnicityNative = true;
			}
			if (ethnicityId == 1) {
				ethnicitiesObj.ethnicityAsian = true;
			}
			if (ethnicityId == 2) {
				ethnicitiesObj.ethnicityPacific = true;
			}
			if (ethnicityId == 3) {
				ethnicitiesObj.ethnicityBlack = true;
			}
			if (ethnicityId == 4) {
				ethnicitiesObj.ethnicityWhite = true;
			}
			if (ethnicityId == 5) {
				ethnicitiesObj.ethnicityHispanic = true;
			}
			if (ethnicityId == 6) {
				ethnicitiesObj.ethnicityTwoOrMore = true;
			}
		});
	}
	return ethnicitiesObj;
}

const convertEthnicitiesObjToString = (ethnicitiesObj) => {
	let ethnicityArray = ""; //of string form

	if (ethnicitiesObj) {
		if (ethnicitiesObj.ethnicityNative) {
			ethnicityArray += "0,"
		}
		if (ethnicitiesObj.ethnicityAsian) {
			ethnicityArray += "1,"
		}
		if (ethnicitiesObj.ethnicityPacific) {
			ethnicityArray += "2,"
		}
		if (ethnicitiesObj.ethnicityBlack) {
			ethnicityArray += "3,"
		}
		if (ethnicitiesObj.ethnicityWhite) {
			ethnicityArray += "4,"
		}
		if (ethnicitiesObj.ethnicityHispanic) {
			ethnicityArray += "5,"
		}
		if (ethnicitiesObj.ethnicityTwoOrMore) {
			ethnicityArray += "6,"
		}
	}

	return ethnicityArray;
}

const getOptionByValue = (options, value) => {
	if (!options) {
		return;
	}
	return options.find((option) => {
		if (option.value === value) return option;
	})
}

const convertOptionsToString = (options) => {
	let optionsString = "" //of string form

	if (options) {
		options.map((p, index) => {
			if (p) {
				optionsString += p.value
				if (index < (options.length - 1)) { optionsString += "," }
			}
		})
	}
	return optionsString
}

const convertStringToOptions = (options, optionString) => {
	let optionsSelected = [];

	if (optionString) {
		(optionString.split(',')).map((option, index) => {
			if (option && option !== "" && option !== " ") {
				optionsSelected.push(getOptionByValue(options, option));
			}
		});
	}

	return optionsSelected;
}

const convertOptionsToStringArray = (options) => {
	let optionsSelected = [];

	if (options) {
		options.map((p, index) => {
			optionsSelected.push(p.value);
		});
	}
	return optionsSelected;
}
/*############################################__GENDERS__############################################*/

const genderArray = [
	{
		id: 1,
		commonName: "male",
		requestName: "Male"
	},
	{
		id: 2,
		commonName: "female",
		requestName: "Female"
	},
	{
		id: 3,
		commonName: "other",
		requestName: "Gender diverse"
	},
	{
		id: 4,
		commonName: "private",
		requestName: "Prefer not to say"
	},
];

const getGenderById = (id) => {
	if (!id || id <= 0 || id > 4) {
		id = -1;
	}
	return genderArray.find((gender) => {
		return gender.id == id;
	})
}

export const getGenderDisplayName = (id) => {
	let gender = getGenderById(id)
	if (gender) {
		return gender.requestName
	}
	return "Not Specified"
}

const getGenderFilterOptions = {
	'1': 'Male',
	'2': 'Female',
	'3': 'Gender diverse',
	'4': 'Prefer not to say',
}

const getOrgTypeFilterOptions = {
	'PRE-K': 'Pre K',
	'K-12': 'K-12',
	'NON-PROFIT': 'Non-Profit',
	'CLINIC': 'Clinical',
	'RESEARCH': 'Research',
	'OTHER': 'Other',
}

const languageOptions = [
	{ id: 1, label: 'English' },
	{ id: 2, label: 'Spanish' },
	{ id: 3, label: 'Dutch' },
	{ id: 4, label: 'Mandarin' },
	{ id: 5, label: 'Swiss German' },
	{ id: 6, label: 'Swedish' },
	{ id: 7, label: 'Somali' },
	{ id: 8, label: 'Hmong' },
	{ id: 9, label: 'French' },
	{ id: 10, label: 'Arabic' },
	{ id: 11, label: 'Turkish' },
	{ id: 12, label: 'Welsh' },
	{ id: 13, label: 'Vietnamese' },
	{ id: 14, label: 'Cantonese' },
	{ id: 15, label: 'Czech' },
	{ id: 16, label: 'Japanese' },
	{ id: 17, label: 'Russian' },
];

const getLanguageById = (id) => {
	let result = languageOptions.find((lang) => {
		return lang.id == id;
	});
	return (result ? result.label : "Unknown");
};

export const partnerOptions = [
	{ value: 'LWS', label: 'Learning Without Scars' },
]

export const featureOptions = [
	{ value: 'ALLOW_PARTICIPANT_EMAIL', label: 'Allow Participant Email Entry' },
]
export function hasFeature(features, featureCode) {
	if (features) {
		return features.includes(featureCode)
	}
	return false;
}

export const typeDetailOptions = [
	{ value: 'CHARTER_SCHOOL', label: 'Charter' },
	{ value: 'UNIVERSITY', label: 'Colleges / University' },
	{ value: 'ECE_CENTERS', label: 'ECE Center' },
	{ value: 'HEADSTART', label: 'Headstart' },
	{ value: 'MONTESSORI', label: 'Montessori' },
	{ value: 'PARTNERS', label: 'Partner' },
	{ value: 'PRESCHOOL', label: 'Preschool' },
	{ value: 'PRIVATE_SCHOOL', label: 'Private School' },
	{ value: 'PUBLIC_SCHOOL', label: 'Public School' },
	{ value: 'STATE_EDU_DEPT', label: 'State Education Department' },
	{ value: 'TUTORING', label: 'Tutoring' },
]

export const atypicalOptions = [
	{ value: 'LOW_INCOME', label: 'Low Income' },
	{ value: 'ASD', label: 'ASD' },
	{ value: 'ADHD', label: 'ADHD' },
	{ value: 'FASD', label: 'FASD' },
	{ value: 'VISION', label: 'Vision Impaired' },
	{ value: 'HEARING', label: 'Hearing Impaired' },
	{ value: 'SPED', label: 'Special Education' },
	{ value: 'OTHER', label: 'Other' },
]

export const targetAgeOptionsEFgo = [
	{ value: 'PRESCHOOL', label: 'Preschool' },
	{ value: 'KINDER', label: 'Kindergarten' },
	{ value: 'GRADE1', label: 'Grade 1' },
	{ value: 'GRADE2', label: 'Grade 2' },
	{ value: 'GRADE3', label: 'Grade 3' },
	{ value: 'GRADE4', label: 'Grade 4' },
	{ value: 'GRADE5', label: 'Grade 5' },
	{ value: 'OTHER', label: 'Other' }
]

export const targetAgeOptions = [
	{ value: 'PRESCHOOL', label: 'Pre-K' },
	{ value: 'KINDER', label: 'Kindergarten' },
	{ value: 'ELEMENTARY', label: 'Elementary' },
	{ value: 'GRADE1', label: 'Grade 1' },
	{ value: 'GRADE2', label: 'Grade 2' },
	{ value: 'GRADE3', label: 'Grade 3' },
	{ value: 'GRADE4', label: 'Grade 4' },
	{ value: 'GRADE5', label: 'Grade 5' },
	{ value: 'GRADE6', label: 'Grade 6' },
	{ value: 'GRADE7', label: 'Grade 7' },
	{ value: 'GRADE8', label: 'Grade 8' },
	{ value: 'GRADE9', label: 'Grade 9' },
	{ value: 'GRADE10', label: 'Grade 10' },
	{ value: 'GRADE11', label: 'Grade 11' },
	{ value: 'GRADE12', label: 'Grade 12' },
	{ value: 'HIGHSCHOOL', label: 'High School' },
	{ value: 'ADOLESCENTS', label: 'Adolescents' },
	{ value: '1-2', label: '1-2 yrs' },
	{ value: '3-4', label: '3-4 yrs' },
	{ value: '5-6', label: '5-6 yrs' },
	{ value: '7-8', label: '7-8 yrs' },
	{ value: '9-10', label: '9-10 yrs' },
	{ value: '11-12', label: '11-12 yrs' },
	{ value: '13-14', label: '13-14 yrs' },
	{ value: '15-16', label: '15-16 yrs' },
	{ value: '17-18', label: '17-18 yrs' },
	{ value: 'ADULT', label: 'Adult' },
	{ value: 'ELDERLY', label: 'Elderly' },
	{ value: 'OTHER', label: 'Other' }
]

const OwnerInfo = {
	name: " Reflective Performance Inc.",
	commonName: " Reflective Performance",
	address: "Newburyport, MA 01950",
	infoEmail: "info@reflectiveperformanceinc.com",
	supportEmail: "support@reflectiveperformanceinc.com",
	telephoneNo: "+16172856425",
	website: "https://www.reflectiveperformanceinc.com/"

}

/*############################################__STATUSES__############################################*/


const statusArray = [
	{
		id: 1,
		commonName: "Active",
		dbName: "ACTIVE"
	},
	{
		id: 2,
		commonName: "Inactive",
		dbName: "INACTIVE"
	}
];

export const EMPLOYEE_STATUSES = [
	{ value: " ", label: "Select Employment Status..." },
	{ value: "FULL-TIME", label: "Full-time" },
	{ value: "PART-TIME", label: "Part-time" },
	{ value: "CONTRACT", label: "Contractor/Consultant" },
	{ value: "STUDENT", label: "Student / Intern" },
];

export const JOB_FUNCTIONS = [
	{ value: " ", label: "Select Job Function..." },
	{ value: "MANAGEMENT", label: "Executive / Management" },
	{ value: "OPERATIONS", label: "Operations / Supply Chain" },
	{ value: "MANUFACTURING", label: "Manufacturing" },
	{ value: "HR", label: "Human Resources (HR)" },
	{ value: "FINANCE", label: "Finance" },
	{ value: "LEGAL", label: "Legal" },
	{ value: "MARKETING", label: "Marketing" },
	{ value: "SALES", label: "Sales" },
	{ value: "SUPPORT", label: "Customer Support / Service" },
	{ value: "DISTRIBUTION", label: "Distribution / Shipping" },
	{ value: "PRODUCT", label: "Product Management" },
	{ value: "ENGINEERING", label: "Technology / Engineering / R&D" },
	{ value: "ADMIN", label: "Administrative" },
	{ value: "STUDENT", label: "Student / Intern" },
	{ value: "OTHER", label: "Other" },
];

const getStatusById = (id) => {
	return statusArray.find((status, index) => {
		return status.id === id;
	})
}

const getStatusFilterOptions = {
	'ACTIVE': 'ACTIVE',
	'INACTIVE': 'INACTIVE',
}

const dropdownOptionsArray = [
	{
		id: 3,
		commonName: "Unknown",
		requestName: "Unknown",
		value: "3"
	},
	{
		id: 2,
		commonName: "Yes",
		requestName: "Yes",
		value: "2"
	},
	{
		id: 1,
		commonName: "No",
		requestName: "No",
		value: "1"
	},
];

const getYesNoById = (id) => {
	let result = dropdownOptionsArray.find((answer) => {
		return answer.id == id;
	});
	return (result ? result : dropdownOptionsArray[0]);
}

/*############################################__MONTHS__############################################*/

const monthsArray = [
	{
		id: 0,
		commonName: "January",
		shortName: "Jan",
		value: "01"
	},
	{
		id: 1,
		commonName: "February",
		shortName: "Feb",
		value: "02"
	},
	{
		id: 2,
		commonName: "March",
		shortName: "Mar",
		value: "03"
	},
	{
		id: 3,
		commonName: "April",
		shortName: "Apr",
		value: "04"
	},
	{
		id: 4,
		commonName: "May",
		shortName: "May",
		value: "05"
	},
	{
		id: 5,
		commonName: "June",
		shortName: "Jun",
		value: "06"
	},
	{
		id: 6,
		commonName: "July",
		shortName: "Jul",
		value: "07"
	},
	{
		id: 7,
		commonName: "August",
		shortName: "Aug",
		value: "08"
	},
	{
		id: 8,
		commonName: "September",
		shortName: "Sep",
		value: "09"
	},
	{
		id: 9,
		commonName: "October",
		shortName: "Oct",
		value: "10"
	},
	{
		id: 10,
		commonName: "November",
		shortName: "Nov",
		value: "11"
	},
	{
		id: 11,
		commonName: "December",
		shortName: "Dec",
		value: "12"
	}
];

const getMonthById = (id) => {
	let result = monthsArray.find((month) => {
		return month.id == id;
	});
	return result;
}

const getTypeOptions = [
	{
		id: 1,
		commonName: "Boolean",
		dbName: "BOOLEAN",
		value: "BOOLEAN",
	},
	{
		id: 2,
		commonName: "Text",
		dbName: "TEXT",
		value: "TEXT"
	},
	{
		id: 3,
		commonName: "Number",
		dbName: "NUMBER",
		value: "NUMBER"
	},
	{
		id: 4,
		commonName: "Array",
		dbName: "ARRAY",
		value: "ARRAY"
	}
];


export {
	ethnicityArray,
	getEthnicityById,
	cleanEthnicityArray,
	convertEthnicityObjToArray,
	convertEthnicityArrayToString,
	convertEthnicitiesArrayToObj,
	convertEthnicitiesObjToString,
	getDisplayableEthnicities,

	getOrgTypeFilterOptions,
	genderArray,
	getGenderById,
	getGenderFilterOptions,

	convertOptionsToString,
	convertStringToOptions,
	convertOptionsToStringArray,

	getLanguageById,

	statusArray,
	getStatusById,
	getStatusFilterOptions,
	OwnerInfo,

	dropdownOptionsArray,
	getYesNoById,

	monthsArray,
	getMonthById,

	getTypeOptions,
};
