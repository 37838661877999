import * as actions from "../actionTypes/userActionTypes";

export const setUserProfile = (userProfile) => {
	return {
		type: actions.SET_USER_PROFILE,
		payload: userProfile
	}
}

export const setUserAccess = (userAccess) => {
	return {
		type: actions.SET_USER_ACCESS,
		payload: userAccess
	}
}

export const setUserDateRange = (presetName, startDate, endDate) => {
	return {
		type: actions.SET_USER_DATE_RANGE,
		payload: {
			dateRange: {
				presetName,
				startDate,
				endDate
			}
		}
	}
}