import { 
	partnerSyncApiURL, 
	singleUserApiURL, singleUserApiURLInOrg, updateEmailSingleUserApiURL, 
	userInfoApiURL, usersApiURL, usersInGroupApiURLChunk, usersInOrgApiURLChunk
} from "./ApiManager/List";
import { API_METHODS } from "./ApiManager/ResponseCodes";
import API_CALL from "./ApiManager";
import { responseVerification } from "./ApiManager/Methods";
import * as Config from '../utils/config/Config';
import { setUserProfile, setUserAccess } from "../redux/actions/userAction";
import { store } from "..";

export const SESSION_CHECK_INTERVAL_MINUTES = 10;

export const userRoleArray = [
	{
		name: "Super Admin (Internal Only)",
		shortName: "Sup Admin",
		code: Config.ROLE_CODE_INTERNAL_SUPER_ADMIN,
		description: "Internal Administrator access (Full access)",
		priorityScore: 999
	},
	/** 
		{
				name: "Support Admin (Internal Only)",
				code: Config.ROLE_CODE_INTERNAL_SUPPORT_ADMIN,
				description: "Internal Support Administrator access",
				priorityScore: 900
		},
	*/
	{
		id: 2000,
		name: "System Admin",
		shortName: "Sys Admin",
		code: Config.ROLE_CODE_SYSTEM_ADMIN,
		description: "System Administrator access to the assigned Organization(s) ",
		priorityScore: 888
	},
	{
		id: 3001,
		name: "Group Admin",
		shortName: "Grp Admin",
		code: Config.ROLE_CODE_GROUP_ADMIN,
		description: "Administer Groups, Sub-Groups, and related data for a particular group",
		priorityScore: 777
	},
	{
		name: "Examiner Level 3",
		shortName: "ExaminerL3",
		code: Config.ROLE_CODE_PARTICIPANT_L3,
		description: "Examiner with assessment, add participant, and view results access",
		priorityScore: 555
	},
	{
		name: "Examiner Level 2",
		shortName: "ExaminerL2",
		code: Config.ROLE_CODE_PARTICIPANT_L2,
		description: "Examiner with assessment and add participant access",
		priorityScore: 444
	},
	{
		name: "Examiner Level 1",
		shortName: "ExaminerL1",
		code: Config.ROLE_CODE_PARTICIPANT_L1,
		description: "Examiner with assessment only access",
		priorityScore: 333
	}
];

export const getUserRoleByCode = (code) => {
	const userRole = userRoleArray.find(userRole => userRole.code === code);
	return userRole || {};
};

export function getUserOrgs(userProfile) {
	let userOrgs = [];
	if (userProfile && userProfile.organizations && userProfile.organizations.length > 0) {
		userOrgs = userProfile.organizations;
	}
	return userOrgs;
}

export function getDefaultOrgId(userProfile) {
	if (userProfile?.organizations?.length > 0) {
		return userProfile.organizations[0].id;
	}
	return 0;
}

export const getOrg = (orgId, userProfile) => {
	if (userProfile) {
		orgId = orgId || getDefaultOrgId(userProfile);
		return userProfile.organizations.find(org => org.id == orgId);
	}
}

export const getOrgPlan = (currentOrgId, userProfile) => {
	let org;
	if (userProfile) {
		const orgId = currentOrgId || getDefaultOrgId(userProfile);
		if (Array.isArray(userProfile.organizations)) {
			org = userProfile.organizations.find(org => org.id === orgId);
		}
	}
	return org?.plan || "";
};

export function getDefaultGroupId(orgId, groups) {
	let defaultGroupId = 0;

	if (Array.isArray(groups) && groups.length > 0) {
		const group = groups.find(group => group.organizationId === orgId);
		if (group) {
			defaultGroupId = group.id;
		}
	}
	return defaultGroupId;
}

export function getUserRole(orgId, userProfile) {
	let userRole = getUserRoleForOrg(orgId, userProfile);

	// If no role is found, get the default user role
	if (!userRole) {
		userRole = getDefaultUserRole(userProfile);
		// If still no role is found, log an error message
		if (!userRole) {
			console.log(`User role not found for userProfile email: ${userProfile?.email}, Org: ${orgId}.`);
		}
	}

	// Return the user role
	return userRole;
}

export function getDefaultUserRole(userProfile) {
	let userRole;
	if (userProfile?.roles?.length > 0) {
		// make sure they have at least 1 role
		if (userProfile.role || userProfile.selectedRole) {
			// the current role is set by the server side code
			let roleCode = (userProfile.role.code ? userProfile.role.code : userProfile.selectedRole.code);
			userRole = getUserRoleByCode(roleCode);
		} else if (userProfile.roles[0].role) {
			// this is for backward compatibility, should be removed at some point when all clients are using current user Role code
			console.log("Get first assigned role (" + userProfile.roles[0].role.code + ").");
			userRole = getUserRoleByCode(userProfile.roles[0].role.code);
		} else {
			console.log("Default user profile role not found.");
		}
	} else {
		console.log("UserProfile (email=" + userProfile?.email + ") role not found.");
	}
	return userRole;
}

export function getUserRoleForOrg(orgId, userProfile) {
	let userRole;

	if (orgId && orgId > 0 && Array.isArray(userProfile?.roles) && userProfile.roles.length > 0) {
		// Find the role for the specified organization
		const roleForOrg = userProfile.roles.find(r => r.orgId === orgId);
		// If a role is found for the organization, get the user role by its code
		if (roleForOrg) {
			userRole = getUserRoleByCode(roleForOrg.role?.code);
		}
	}
	return userRole;
}

export function isValidUserProfile(result) {
	if (!result.data || result.data.length === 0 || !result.data[0] ||
		!result.data[0].roles || result.data[0].roles.length === 0 ||
		!result.data[0].organizations || result.data[0].organizations.length === 0 ||
		result.data[0].status !== "ACTIVE") {
		return false;
	}
	return true;
}

export const getUserProfile = async (accessToken, currentOrgId, userProfile, isAuthenticated, forceRefresh = false) => {
	// TODO: enable this and test
	//if (!accessToken) {
	//	return null;
	//}
	if (!forceRefresh && isAuthenticated && userProfile) {
		// already found the user in this auth session
		return userProfile;
	} else {
		// get from API
		return new Promise((resolve, reject) => {
			refreshUserProfile(accessToken, currentOrgId, (userProfile) => {
				if (!userProfile) {
					reject(new Error("Failed to retrieve user profile."));
				} else {
					resolve(userProfile);
				}
			});
		});
	}
}

export const getLocalAccessToken = () => {
	return localStorage.getItem("accessToken")
}

export const getCurrentOrgId = () => {
	return localStorage.getItem("currentOrgId")
}

export const setLocalAccessToken = async (accessToken, idToken) => {
	await localStorage.setItem("accessToken", accessToken);
	await localStorage.setItem("idToken", idToken);
}

export const clearLocalUser = async () => {
	//await store.dispatch({ type: RESET_USER_STATE });

	// remove legacy storage values
	await localStorage.removeItem("accessToken");
	await localStorage.removeItem("idToken");
	await localStorage.removeItem("currentOrgId");
	await localStorage.removeItem("isLoggedIn");
	await localStorage.removeItem("RSAdminViewed");
	await localStorage.removeItem("email");
}

export const refreshUserProfile = (accessToken, currentOrgId, successCallback, errorCallback, isVerificationRequired = true) => {
	// TODO: enable this and test
	//if (!accessToken) {
	//	return null;
	//}
	let userProfile;
	API_CALL(accessToken, API_METHODS.GET, userInfoApiURL, null, async (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			await clearLocalUser();
			errorCallback(successData.statusCode);
		} else {
			userProfile = successData.data[0];
			userProfile.fullName = userProfile.firstName + " " + userProfile.lastName;
			// store local user profile and user access
			await store.dispatch(setUserProfile(userProfile))

			// set local access token for reporting/puppeteer
			await setLocalAccessToken(accessToken)

			// set local access for current org, role, etc.
			let orgId = currentOrgId || getDefaultOrgId(userProfile);
			await setLocalUserAccess(
				orgId,
				getDefaultGroupId(orgId, userProfile.groups),
				getUserRole(orgId, userProfile),
				getOrgPlan(orgId, userProfile)
			)
			if (successCallback) successCallback(userProfile);
		}
	}, async (error) => {
		console.log("Error calling UserInfoAPI. error = " + error);
		await clearLocalUser();
		if (errorCallback) errorCallback(error);
	});
};


export const setLocalUserAccess = async (orgId, groupId, userRole, orgPlan) => {
	console.log("setting local user access...")
	await store.dispatch(setUserAccess({
		currentOrgId: orgId,
		currentGroupId: groupId,
		currentUserRole: userRole,
		currentOrgPlan: orgPlan
	}));

	// setting legacy values for print/puppeteer
	await localStorage.setItem("currentOrgId", orgId);
}

export const getPartnerSyncApiURL = (accessToken, partnerId, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = partnerSyncApiURL(partnerId)
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getSingleUserData = (accessToken, userId, orgId, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = singleUserApiURLInOrg(userId, orgId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const updateSingleUserData = (accessToken, userId, payload, methods = "user", successCallback, errorCallback, isVerificationRequired = true) => {
	let url = (methods === "email") ? updateEmailSingleUserApiURL(userId) : singleUserApiURL(userId)
	let method = API_METHODS.PUT
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const deleteSingleUserData = (accessToken, userId, orgId, groupId, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = singleUserApiURL(userId) + "?organizationId=" + orgId + "&groupId=" + (groupId ? groupId : 0)
	let method = API_METHODS.DELETE;
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getUsersChunkData = (accessToken, orgId, groupId, offset, sizePerPage, sortField, sortOrder, filters, successCallback, errorCallback, isVerificationRequired = true) => {
	var url = usersInOrgApiURLChunk(orgId, offset, sizePerPage, sortField, sortOrder, filters);
	if (groupId) {
		url = usersInGroupApiURLChunk(orgId, groupId, offset, sizePerPage, sortField, sortOrder, filters);
	}
	let method = API_METHODS.GET;
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const addSingleUserData = (accessToken, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = usersApiURL;
	let method = API_METHODS.POST;
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

