import { getGroupTypeLabel, getSubGroupType, getSubGroupTypeLabel } from "../../utils/Utils";
import * as actions from "../actionTypes/orgActionTypes";

export const setOrganizationDetails = (result) => {
    return {
      type: actions.SET_ORGANIZATION,
      payload: {
        organization: result.data[0],
        entityPath: result.entityPath ? result.entityPath : [],
        organizationDisplayName: result.data[0].name ? result.data[0].name : result.data[0].secondaryName,
        loaded: true
      }
    }
}

export const setGroupDetails = (result) => {
  return {
    type: actions.SET_GROUP,
    payload: {
      loaded: true,
      groupData: result.data[0],
      groupAlias: getGroupTypeLabel(result.data[0] ? result.data[0].type : null ),
      subGroupType: getSubGroupType(result.data[0] ? result.data[0].type : null ),
      subGroupAlias: getSubGroupTypeLabel(result.data[0] ? result.data[0].type : null ),
      entityPath: result.entityPath ? result.entityPath : []
    }
  }
}

export const setOrganizationSummary = (result) => {
  return {
    type: actions.SET_ORGANIZATION_SUMMARY,
    payload: {
      loaded: true,
      orgSummary: result.data[0]
    }
  }
}

export const setGroupSummary = (result) => {
  return {
    type: actions.SET_GROUP_SUMMARY,
    payload: {
      loaded: true,
      groupSummary: result.data[0]
    }
  }
}

export const setOrganizationTestSummary = (result) => {
  return {
    type: actions.SET_ORGANIZATION_TEST_SUMMARY,
    payload: {
      loaded: true,
      orgTestSummary: result.data
    }
  }
}
export const setGroupTestSummary = (result) => {
  return {
    type: actions.SET_GROUP_TEST_SUMMARY,
    payload: {
      loaded: true,
      groupTestSummary: result.data
    }
  }
}
export const setShowInactive = (value) => {
  return {
    type: actions.SET_SHOW_INACTIVE,
    payload: value
  }
}