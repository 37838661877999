import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { gotoPage, Logger } from "../../utils/Utils";
import FailureModal from '../modals/FailureModal';
import Loading from "../misc/Loading";
import logo from '../../assets/img/brand/logo.png';
import { getUserProfile } from '../../managers/UserManager';
import { useSelector } from 'react-redux';

const FILE_NAME = "PartnerLaunch.js";

export default function Launch (props) {
	const userState = useSelector(state=>state.user);
  const { partnerId, externalTenantId, externalClassId, externalUserId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingState, setLoadingState] = useState(0);
  const [failureModalVisible, setFailureModalVisible] = useState(false);
  const [org, setOrg] = useState(null);
  const [error, setError] = useState(null);

  
  useEffect(()=>{
    Logger(FILE_NAME, "render", "", "props", props);

    let payload = { 
      partnerId : partnerId,
      externalTenantId : externalTenantId,
      externalClassId : externalClassId,
      externalUserId : externalUserId,
    };

    getPartnerSyncApiURL(userState.userAccess?.accessToken, partnerId, JSON.stringify(payload),  (result) => {
      Logger(FILE_NAME, "partnerSyncApiUrl", "fetch1", "result", result);
      setOrg(result.data[0]);
      setIsLoaded(true);
      setError(null);
      if (org && org.id > 0) {
        // refresh user profile
        getUserProfile(userState.userAccess?.accessToken, userState.userAccess?.currentOrgId, userState.userProfile, false, true).then((userProfile) => { gotoPage("/"); });
      } else {
        setIsLoaded(true);
        setLoadingState(2);
        setFailureModalVisible(true);
        toggleFailureModal();
      }
        },
        (error) => {
      Logger(FILE_NAME, "useEffect", "Fetch1", "error", error);
      setError(error);
      setIsLoaded(true);
      setLoadingState(2);
      setFailureModalVisible(true);
      toggleFailureModal();
    });
  },[]);

  const toggleFailureModal = () => {
    setLoadingState(2);
    setFailureModalVisible(!failureModalVisible);
  };
    
  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          &nbsp;
        </Col>
        <Col sm={12} md={10} lg={8} xl={6}>
          <center><img src={logo} height="120px" alt=""/>
            <Loading message={(!isLoaded ? "Synchronizing Classroom..." : (loadingState === 2 ? "Error synchronizing account.  Please verify source account configuration (e.g. email address, permissions/roles)." : "Redirecting..." ))} loadingState={loadingState} />
          </center>
        </Col>
        <Col>
          &nbsp;
        </Col>
      </Row>
      <FailureModal visible={failureModalVisible} toggleVisibility={toggleFailureModal} />
    </div>
  );
}