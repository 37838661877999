import { PLAN_REFLECT_EF } from "../../managers/OrgManager"; 
import * as actions from "../actionTypes/helpActionTypes";

let initialState = {
  helpTopicName: "general",
  orgPlanType: PLAN_REFLECT_EF,
  isLoaded: false,
};

export default function helpReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_HELP_TOPIC:
      return {
        ...state,
        helpTopicName: action.payload,
      };
    case actions.SET_ORG_PLAN_TYPE:
      return {
        ...state,
        orgPlanType: action.payload.planType,
        isLoaded: action.payload.isLoaded,
      };
		case actions.RESET_STATE:
			return initialState;
    default:
      return state;
  }
}
