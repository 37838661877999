import { ResponseCodes } from "./ResponseCodes";
import { setFailureStatus, setSessionTimeoutStatus, setUnauthorizedStatus } from "../../redux/actions/appActions";
import { gotoPage } from "../../utils/Utils";
import { store } from "../..";

/*
This method checks if the response-status of an HTTP response is not 200.
To be used with "fetch" method.
*/
export const responseHandler = (res) => {
	if (res.status != 200) {
		if (res.status == 401) { gotoPage('/account/sessiontimeout') }
		throw new Error(res.status, "HTTP-RESPONSE-STATUS NOT 200");
	} else { return res.json(); }
};

export const responseVerification = (statusCode, showDialog = true) => {
	switch (statusCode) {
		case ResponseCodes.OK:
		case ResponseCodes.HTTP_OK:
			return true
		case ResponseCodes.CREATED:
			return false
		case ResponseCodes.BAD_REQUEST:
		case ResponseCodes.NOT_CREATED:
		case ResponseCodes.INVALID_JSON:
		case ResponseCodes.FORBIDDEN:
		case ResponseCodes.NOT_FOUND:
		case ResponseCodes.REQUEST_TIMEOUT:
		case ResponseCodes.INTERNAL_SERVER_ERROR:
		case ResponseCodes.SERVICE_UNAVILABLE:
		case ResponseCodes.GATEWAY_TIMEOUT:
		case ResponseCodes.CONNECTION_TIMEOUT:
		case ResponseCodes.VALIDATION_FAILED:
		case ResponseCodes.TIMEOUT_OCCURED:
		case ResponseCodes.NOT_UPDATE:
			if (showDialog) {
				store.dispatch(setFailureStatus(true));
			}
			return false
		case ResponseCodes.UNAUTHORIZED:
			if (showDialog) {
				store.dispatch(setUnauthorizedStatus(true));
			}
			return false
		case ResponseCodes.INVALID_TOKEN:
			store.dispatch(setSessionTimeoutStatus(true))
			return false
	}
}