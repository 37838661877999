import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { goHome } from '../../utils/Utils';
import { OwnerInfo } from "../../utils/Enums";
import { store } from '../..';
import { setFailureStatus } from '../../redux/actions/appActions';

export default function FailureModal(props) {

	const handleContinue = () => {
		store.dispatch(setFailureStatus(false));
		if (props.toggleVisibility) props.toggleVisibility();
	}

	const handleHome = () => {
		store.dispatch(setFailureStatus(false));
		goHome();
	}
      return(
        <Modal isOpen={props.visible} backdrop={"static"} className={'modal-danger'}>
          <ModalHeader toggle={props.toggleVisibility}>Error</ModalHeader>
          <ModalBody>
            <div className="animated fadeIn pt-3 text-center text-danger">
              <i className="fa fa-warning" style={{ color: "red", fontSize: "40px" }} />
              <h2>We're Sorry.</h2>
              <div>
                An Error has occurred, please try again.<br/>
                If it continues to occur please contact <br /><a href={"mailto:" + OwnerInfo.supportEmail}>{OwnerInfo.supportEmail}</a>
              </div>
            </div >
          </ModalBody>
          <ModalFooter>
				<Button color="primary" onClick={handleContinue}>&nbsp;&nbsp;OK&nbsp;&nbsp;</Button>
				<Button color="secondary" onClick={handleHome}> HOME </Button>
          </ModalFooter>
        </Modal>
    );
}
