import moment from "moment";


export const dateFormatInDB = "YYYY-MM-DD";

export const generateMoment = (month, year) => {
	/*
	This method will combine month[0-11] and year, into one moment-obj , with date always as default-1
	*/
	const generatedMoment = new moment().year(year).month(month).date(1);

	return generatedMoment;
}

export const convertMilliSecToMMSS = (millis) => {
	let dur = moment.duration(millis, 'ms');
	let formattedTime = "";
	formattedTime += dur.get('d') > 0 ? (dur.get('d') + "d ") : ""
	formattedTime += dur.get('h') > 0 ? (dur.get('h') + "h ") : ""
	formattedTime += dur.get('m') > 0 ? (dur.get('m') + "m ") : ""
	formattedTime += dur.get('s') > 0 ? (dur.get('s') + "s") : ""
	return formattedTime == "" ? msConversion(formattedTime) : formattedTime;
}

function msConversion(millis) {
	let sec = Math.floor(millis / 1000);
	let hrs = Math.floor(sec / 3600);
	sec -= hrs * 3600;
	let min = Math.floor(sec / 60);
	sec -= min * 60;

	sec = '' + sec;
	sec = ('0' + sec).substring(sec.length);

	if (hrs > 0) {
		min = '' + min;
		min = ('0' + min).substring(min.length);
		return hrs + "h " + min + "m " + sec + "s";
	}
	else {
		return min + "m " + sec + "s";
	}
}

export const formatDuration = (millis, isAbbrev) => {
	var dur = moment.duration(millis, 'ms');
	let formattedTime = "";
	let hrs = dur.get('h');
	if (hrs > 0) {
		if (hrs === 1) {
			formattedTime += "1" + (isAbbrev ? "h" : " hour")
		} else {
			formattedTime += hrs + (isAbbrev ? "h" : " hours")
		}
	}
	let min = dur.get('m');
	if (min > 0) {
		formattedTime += hrs > 0 ? " " : ""
		if (min === 1) {
			formattedTime += "1" + (isAbbrev ? "m" : " minute")
		} else {
			formattedTime += min + (isAbbrev ? "m" : " minutes")
		}
	}
	let sec = dur.get('s');
	if (hrs === 0 && min === 0 && sec > 0) {
		formattedTime += min > 0 ? " " : ""
		if (sec === 1) {
			formattedTime += "1 " + (isAbbrev ? "s" : " second")
		} else {
			formattedTime += sec + (isAbbrev ? "s" : " seconds")
		}
	}
	return formattedTime;
}

export const formatNoOfMonths = (totalNoOfMonths) => {
	/* This method will convert 34-months to ( 2 years 10 months )  */

	totalNoOfMonths = (Math.round(totalNoOfMonths));

	const noOfYears = parseInt(totalNoOfMonths / 12);
	const noOfMonths = parseInt(totalNoOfMonths % 12);

	const __YEARS_LITERAL__ = (noOfYears > 1) ? "years" : "year";
	const __MONTHS_LITERAL__ = (noOfMonths > 1) ? "months" : "month";

	const yearsString = (noOfYears > 0) ? `${noOfYears} ${__YEARS_LITERAL__} ` : "";
	const monthsString = (noOfMonths > 0) ? `${noOfMonths} ${__MONTHS_LITERAL__}` : "";

	const formattedString = `${yearsString}${monthsString}`;

	return formattedString;
}

export function isValidDate(date) {
	if (!date) {
		return false
	}
	return moment(date).isValid()
}

export function formatLocalizedDate(date, format, missingDisplayValue) {
	if (!date || date === 'null' || date.length === 0) {
		if (!missingDisplayValue) {
			return missingDisplayValue;
		} else {
			return "-";
		}
	}
	if (!format) {
		format = 'MM-DD-YYYY';
	}
	return moment.utc(date).local().format(format);
}

export function formatDate(date, format, missingDisplayValue) {
	if (!date || date === 'null' || date.length === 0) {
		if (!missingDisplayValue) {
			return missingDisplayValue;
		} else {
			return "-";
		}
	}
	if (!format) {
		format = 'MM-DD-YYYY';
	}
	return moment(date).format(format);
}

export function isExpired(date) {
	var today = moment().format("YYYY-MM-DD");
	var expiryDate = moment(date, "YYYY-MM-DD");
	if (expiryDate.isBefore(today)) {
		return true;
	} else {
		return false;
	}
}
/**
 * Checks if the date ranges are overlapping. Checks if the given date range (start and end date)
 * overlaps with another start and end date.
 * @param {string} startDate1
 * @param {string} endDate1 
 * @param {string} startDate2 
 * @param {string} endDate2 
 * @returns {boolean}
 */
export function isOverlapping(startDate1, endDate1, startDate2, endDate2) {
	let startDate1Moment = startDate1 ? moment(startDate1, "YYYY-MM-DD") : moment(-8640000000000000); // Minimum timestamp in JS
	let endDate1Moment = endDate1 ? moment(endDate1, "YYYY-MM-DD") : moment(8640000000000000); // Maximum timestamp in JS
	let startDate2Moment = startDate2 ? moment(startDate2, "YYYY-MM-DD") : moment(-8640000000000000);
	let endDate2Moment = endDate2 ? moment(endDate2, "YYYY-MM-DD") : moment(8640000000000000);

	if (startDate1Moment.isSameOrBefore(endDate2Moment) && endDate1Moment.isSameOrAfter(startDate2Moment)) {
		return true;
	} else {
		return false;
	}
}

/**
* Checks if the given date falls within (inclusive) of the start and end date.
* @param {string} date 
* @param {string} startDate 
* @param {string} endDate 
* @returns {boolean}
*/
export function isWithin(date, startDate, endDate) {
	let dateMoment = moment(date, "YYYY-MM-DD");
	let startDateMoment = startDate ? moment(startDate, "YYYY-MM-DD") : moment(-8640000000000000); // Minimum timestamp in JS
	let endDateMoment = endDate ? moment(endDate, "YYYY-MM-DD") : moment(8640000000000000); // Maximum timestamp in JS

	if (dateMoment.isSameOrAfter(startDateMoment) && dateMoment.isSameOrBefore(endDateMoment)) {
		return true;
	} else {
		return false;
	}
}

export function addToDate(date, num, unit) {
	return moment(new Date(date)).add(num, unit);
}

export function differenceInMonths(date1, date2) {
	return difference(date1, date2, 'months');
}

export function differenceInWeeks(date1, date2) {
	return difference(date1, date2, 'weeks');
}
export function differenceInDays(recentDate, olderDate) {
	return difference(recentDate, olderDate, 'day');
}
export function difference(date1, date2, unit) {
	return moment(new Date(date1)).diff(new Date(date2), unit, true);
}

export const formatReactionTime = (timeInMillis) => {
	return (timeInMillis > 0 ? (timeInMillis / 1000) + "s" : "-");
}

export const getDateRangeText = (startDate, endDate) => {
	let dateRangeText
	let isValidStartDate = isValidDate(startDate)
	let isValidEndDate = isValidDate(endDate)
	if (isValidStartDate && isValidEndDate) {
		dateRangeText = formatDate(startDate, "M/D/YYYY") + "  -  " + formatDate(endDate, "M/D/YYYY")
	} else if (isValidStartDate) {
		dateRangeText = formatDate(startDate, "M/D/YYYY") + "  -  Today";
	} else if (isValidEndDate) {
		dateRangeText = "Ending " + formatDate(endDate, "M/D/YYYY")
	}
	return dateRangeText
}
export const getTimeframe = (startDate, endDate) => {
	let dateRangeText
	let isValidStartDate = isValidDate(startDate)
	let isValidEndDate = isValidDate(endDate)
	if (isValidStartDate && isValidEndDate) {
		dateRangeText = formatDate(startDate, "M/D/YYYY") + "  -  " + formatDate(endDate, "M/D/YYYY")
	} else if (isValidStartDate) {
		dateRangeText = formatDate(startDate, "M/D/YYYY") + "  -  " + formatDate(moment(), "M/D/YYYY")
	} else if (isValidEndDate) {
		dateRangeText = "Ending " + formatDate(endDate, "M/D/YYYY")
	}
	return dateRangeText
}