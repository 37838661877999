
import {
	requestHeaderGET, requestHeaderPOST, requestHeaderPUT, requestHeaderDELETE,
	requestHeaderFilePOST, requestHeaderFilePUT
} from "./Header";
import { responseHandler } from "./Methods";
import { API_METHODS } from './ResponseCodes';

const getHeader = (accessToken, method, data = null) => {
	switch (method) {
		case API_METHODS.GET:
			return requestHeaderGET(accessToken)
		case API_METHODS.POST:
			return requestHeaderPOST(accessToken, JSON.stringify(data))
		case API_METHODS.PUT:
			return requestHeaderPUT(accessToken, JSON.stringify(data))
		case API_METHODS.DELETE:
			return requestHeaderDELETE(accessToken)
		case API_METHODS.FILE_PUT:
			return requestHeaderFilePUT("", data)
		case API_METHODS.FILE_POST:
			return requestHeaderFilePOST(accessToken, JSON.stringify(data))
	}
}

async function API_CALL(accessToken, method, url, data, callback, errorCallback, isJsonResponse = true) {
	let header = getHeader(accessToken, method, data)
	fetch(url, header)
		.then(res => responseHandler(res, isJsonResponse))
		.then(
			(result) => {
				callback(result)
			},
			(error) => {
				errorCallback(error)
			}
		)
}

export default API_CALL;
