import * as actions from "../actionTypes/appActionTypes";

export const setUnauthorizedStatus = (status = false) => {
  return {
    type: actions.SET_UNAUTHORIZED_STATUS,
    payload: status
  };
};

export const setFailureStatus = (status = false) => {
  return {
    type: actions.SET_FAILURE_STATUS,
    payload: status
  };
};

export const setSessionTimeoutStatus = (status = false) => {
  return {
    type: actions.SET_SESSION_FAILURE_STATUS,
    payload: status
  };
};
export const setDataPrivacyMode = (isDataPrivacyMode = false) => {
	return {
		type: actions.SET_DATA_PRIVACY_MODE,
		payload: isDataPrivacyMode
	};
};
export const resetAppState = () => {
  return {
    type: actions.RESET_APP_STATE,
  };
};
