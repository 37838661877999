import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Loading from "../misc/Loading";
import logo from '../../assets/img/brand/logo.png';
import { useAuth0 } from '@auth0/auth0-react'

export default function PartnerAuth () {
	const { loginWithRedirect } = useAuth0();
	let location = useLocation();

	useEffect(()=>{
		localStorage.clear();
		sessionStorage.clear();
		dispatch({ type: RESET_STATE })
		loginWithRedirect({
			authorizationParams: {
				redirect_uri: location.pathname
			}
		});	
	},[]);

	return (
		<div className="animated fadeIn">
			<Row>
				<Col>
					&nbsp;
				</Col>
				<Col sm={10} md={8} lg={6} xl={4}>
					<center><img src={logo} height="100px" alt="" />
						<Loading message={"Authenticating..."} />
					</center>
				</Col>
				<Col>
					&nbsp;
				</Col>
			</Row>
		</div>
	)
}