import React from 'react';

export const STATE_LOADING = 0;
export const STATE_SUCCESS = 1;
export const STATE_ERROR = 2;
export const STATE_UNAUTHORIZED = 9;
	
export default function Loading(props) {

	const failure = () =>
		<div className="animated fadeIn pt-3 text-center text-danger">
			<i className="fa fa-warning" style={{ color: "red", fontSize: "40px" }} />
			<div className="text-danger">{(props.message ? props.message : "E R R O R")}</div>
		</div >
		;

	const loading = () =>
		<div className="animated fadeIn pt-3 text-center">
			<br />
			<div className="spinner-border text-secondary" role="status"></div><br />
			<div className="text-secondary">{(props.message ? props.message : "")}</div>
			<br />
		</div>
		;

	const success = () =>
		<div className="animated fadeIn pt-3 text-center">
			<div className="fa fa-check text-success" role="status"></div><br />
			<p className="text-success">{(props.message ? props.message : "")}</p>
		</div>
		;

	const unauthorized = () =>
		<div className="animated fadeIn pt-3 text-center">
			<i className="fa fa-lock text-secondary" style={{ fontSize: '40px' }}></i>
			<p className="text-primary">
				<h4>Unauthorized</h4>
				You are not authorized to view this information.<br />
				Please contact your system administrator for access.
			</p>
			<p className="text-secondary">{(props.message ? props.message : "")}</p>
		</div>
		;

	let message = <div></div>;
	if (props.loadingState === STATE_SUCCESS) {
		message = success();
	} else if (props.loadingState === STATE_ERROR) {
		message = failure();
	} else if (props.loadingState === STATE_UNAUTHORIZED) {
		message = unauthorized();
	} else {
		message = loading();
	}
	return (
		<>{message}</>
	)
}
