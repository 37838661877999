
export const ResponseCodes = {
	OK: 1001,
	CREATED: 1002,
	BAD_REQUEST: 1003,
	NOT_CREATED: 1004,
	INVALID_JSON: 1005,
	UNAUTHORIZED: 1006,
	FORBIDDEN: 1007,
	NOT_FOUND: 1008,
	REQUEST_TIMEOUT: 1009,
	INTERNAL_SERVER_ERROR: 1010,
	SERVICE_UNAVILABLE: 1011,
	GATEWAY_TIMEOUT: 1012,
	CONNECTION_TIMEOUT: 1013,
	VALIDATION_FAILED: 1014,
	TIMEOUT_OCCURED: 1015,
	NOT_UPDATE: 1016,
	INVALID_TOKEN: 1017,
	INVALID_SEARCH: 1018,
		HTTP_OK: 200,
		HTTP_CLIENT_ERROR: 400,
		HTTP_SERVER_ERROR: 500,
}

export const API_METHODS = {
	GET: 0,
	POST: 1,
	PUT: 2,
	DELETE: 3,
	FILE_POST: 4,
	FILE_PUT: 5
}