import * as actions from "../actionTypes/userActionTypes";

let initialState = {
	userProfile: null,
	userAccess: null,
	loaded: false,
	releaseNotesVersion: null,
	dateRange: null,
};

export default function userProfileReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SET_USER_PROFILE:
			return {
				...state,
				userProfile: {
					...state.userProfile,
					...action.payload,
				},
			};
		case actions.SET_USER_ACCESS:
			return {
				...state,
				userAccess: {
					...state.userAccess,
					...action.payload,
				},
			};
		case actions.SET_USER_DATE_RANGE:
			return {
				...state,
				dateRange: action.payload.dateRange
			};
		case actions.RESET_STATE:
		case actions.RESET_USER_STATE:
			return initialState;
    default:
      return state;
  }
}