import * as actions from '../actionTypes/appActionTypes'

let initialState = {
    unauthorizedModalVisible: false,
    sessionTimeoutModalVisible: false,
	failureModalVisible: false,
	isDataPrivacyMode: false
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_UNAUTHORIZED_STATUS: 
            return {
                ...state,
                failureModalVisible: false,
                sessionTimeoutModalVisible: false,
                unauthorizedModalVisible: action.payload
            };
        case actions.SET_FAILURE_STATUS:
            return {
                ...state,
                unauthorizedModalVisible: false,
                sessionTimeoutModalVisible: false,
                failureModalVisible: action.payload
            };
        case actions.SET_SESSION_FAILURE_STATUS:
            return {
                ...state,
                unauthorizedModalVisible: false,
                failureModalVisible: false,
                sessionTimeoutModalVisible: action.payload
            };
		case actions.SET_DATA_PRIVACY_MODE:
			return {
				...state,
				isDataPrivacyMode: action.payload,
			};
		case actions.RESET_STATE:
		case actions.RESET_APP_STATE:
			return initialState;
        default:
            return state;
    }
}