import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardBody } from "reactstrap";
import { Button } from "reactstrap";
import { gotoPage } from "../../utils/Utils";
import { resetAppState } from "../../redux/actions/appActions";


function SessionTimeout() {

	const { loginWithRedirect } = useAuth0();
	const dispatch = useDispatch();

	useEffect(() => {
		// clear state
		dispatch(resetAppState())
	}, [])

	function gotoClearLocalPage() {
		gotoPage("/logout")
	}

	return (
		<div className="sessionMain">
			<Card className="sessionDialog">
				<CardBody>
					<h4>Your session has ended</h4>
					<p>You may close the browser or login</p>
					<Button	color="primary"	onClick={loginWithRedirect}><b>LOGIN</b></Button>
					<hr/>
					<p>
						<small>
							Having trouble logging back in?<br/>
							<Button block color="ghost-secondary" className="text-center mt-2" size="sm" onClick={gotoClearLocalPage}>
								<b>Reset Admin Portal browser settings</b>
							</Button>
						</small>
					</p>
				</CardBody>
			</Card>
		</div>
	);
}


export default SessionTimeout;